import axios from "axios";

import { apiKey, timestamp, secureHash } from "@util/authInfo";

axios.defaults.baseURL = "/api";

axios.interceptors.request.use(
  (request) => {
    request.headers["api-key"] = apiKey;
    request.headers["timestamp"] = timestamp;
    request.headers["secure-hash"] = secureHash;

    if (
      requestWhitelist.includes(request.url) ||
      request.url.indexOf("/code/") > -1 ||
      request.url.indexOf("/verify-code/") > -1 ||
      request.url.indexOf("/set-session/") > -1 ||
      request.url.indexOf("microsite") > -1 ||
      request.url.indexOf("assets") > -1 ||
      request.url.indexOf("gallery") > -1 ||
      request.url.indexOf("alerts") > -1 ||
      request.url.indexOf("leads") > -1 ||
      request.url.indexOf("trouble-tickets") > -1 ||
      request.url.indexOf("toggle-email-stream") > -1 ||
      request.url.indexOf("assets/form-upload/") > -1 ||
      (request.url.indexOf("ride-challenge") > -1 &&
        window.location.pathname.indexOf("ride-challenge-interactions") ===
          -1) ||
      (request.url.indexOf("ride-challenge") > -1 &&
        window.location.pathname.indexOf("ride-challenge-interactions") ===
          -1 &&
        window.location.pathname.indexOf("efforts") === -1) ||
      (request.url.indexOf("user-vehicles") > -1 &&
        window.location.pathname.indexOf("efforts") === -1) ||
      (request.url.indexOf("loyalty-memberships") > -1 &&
        window.location.pathname.indexOf("ride-challenge-interactions") ===
          -1 &&
        window.location.pathname.indexOf("efforts") === -1) ||
      request.url.indexOf("/forgot-password") > -1
    ) {
      request.headers["internal"] = true;
    }

    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    // * if response isn't set up with response codes
    if (response.data.response_code === undefined) {
      return response;
    }

    // return to login
    if (
      response.data.response_code === 2 &&
      response.data.response_message &&
      response.data.response_message === "Back to Login"
    ) {
      // window.location = `/admin`;
      if (localStorage.getItem("mixer-entry-point")) {
        return (window.location = localStorage.getItem("mixer-entry-point"));
      }

      return (window.location = `/`);
    }

    // modal
    if (response.data.response_code === 3) {
      return response;
    }

    // ! throw the response message
    if (response.data.response_code !== 0) {
      if (response.data.response_code === 2 && response.data.response) {
        // return response;
        return response;
      }
      throw response.data.response_message;
    }

    return response;
  },
  (error) => {
    if (import.meta.env.VITE_ENV === "dev") {
      // console.log(error);
    }
    let formattedError =
      typeof error !== "string"
        ? `We need to refresh your session. Please reload this page.`
        : error;

    return Promise.reject(formattedError);
  }
);

export default axios;

const requestWhitelist = [
  `/admin-login`,
  `/forgot-password`,
  `/manager-invite-decision`,
  `/manager-login`,
  `/members/complete-signup`,
  `/members/resend-signup-email-link`,
  `/members/signup`,
  `/password`,
  `/resend-signup`,
  `/send-otp-code`,
  `/status`,
  `/user-login`,
  `/verifications/phone/check`,
  `/verifications/phone`,
  `/verify-text-code`,
].map((w) => `${w}/`);
