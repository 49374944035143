import React from "react";
import {
  IconAward,
  IconBarbell,
  IconBook,
  IconBuilding,
  IconCalendar,
  IconChartBar,
  IconChartInfographic,
  IconPlus,
  IconClipboardText,
  IconFlask,
  IconGauge,
  IconGift,
  IconKey,
  IconMail,
  IconMapPin,
  IconMessageCircle,
  IconMusic,
  IconNotes,
  IconPhone,
  IconPlayerPlay,
  IconSend,
  IconSpeakerphone,
  IconTicket,
  IconTrash,
  IconUser,
  IconUsers,
  IconWorld,
  IconListDetails,
  IconChartArrowsVertical,
  IconUserShield,
  IconWheel,
} from "@tabler/icons-react";

const entityIcon = {
  add: (size = 16) => <IconPlus size={size} />,
  admin: (size = 16) => <IconUserShield size={size} />,
  artist: (size = 16) => <IconUsers size={size} />,
  calendar: (size = 16) => <IconCalendar size={size} />,
  campaign: (size = 16) => <IconSpeakerphone size={size} />,
  contest: (size = 16) => <IconAward size={size} />,
  curation: (size = 16) => <IconMusic size={size} />,
  dashboard: (size = 16) => <IconGauge size={size} />,
  effort: (size = 16) => <IconBarbell size={size} />,
  email: (size = 16) => <IconMail size={size} />,
  entry: (size = 16) => <IconTicket size={size} />,
  keyword: (size = 16) => <IconKey size={size} />,
  organization: (size = 16) => <IconBuilding size={size} />,
  location: (size = 16) => <IconMapPin size={size} />,
  loot: (size = 16) => <IconGift size={size} />,
  manager: (size = 16) => <IconClipboardText size={size} />,
  message: (size = 16) => <IconMessageCircle size={size} />,
  phone: (size = 16) => <IconPhone size={size} />,
  play: (size = 16) => <IconPlayerPlay size={size} />,
  recipe: (size = 16) => <IconFlask size={size} />,
  report: (size = 16) => <IconChartBar size={size} />,
  rideChallenge: (size = 16) => <IconWheel size={size} />,
  send: (size = 16) => <IconSend size={size} />,
  song: (size = 16) => <IconMusic size={size} />,
  trash: (size = 16) => <IconTrash size={size} />,
  user: (size = 16) => <IconUser size={size} />,
  library: (size = 16) => <IconBook size={size} />,
  ticket: (size = 16) => <IconNotes size={size} />,
  api: (size = 16) => <IconChartInfographic size={size} />,
  session: (size = 16) => <IconListDetails size={size} />,
  request: (size = 16) => <IconChartArrowsVertical size={size} />,
  general: (size = 16) => <IconWorld size={size} />,
};

export default entityIcon;
